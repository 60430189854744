* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}
html,body,address,blockquote,div,dl,form,h1,h2,h3,h4,h5,h6,ol,p,pre,table,ul,dd,dt,li,tbody,td,tfoot,th,thead,tr,button,del,ins,map,object,a,abbr,acronym,b,bdo,big,br,cite,code,dfn,em,i,img,kbd,q,samp,small,span,strong,sub,sup,tt,var,legend,
fieldset,p, aside, header, footer, section, article {
	border: medium none;
	margin: 0;
	padding: 0;
    
}

:focus
{
	outline: none !important;
}

img,fieldset 
{
	border: 0 none;
}

a
{
	border: none;
	outline: none;
}

input[type="submit"], select
{
	-webkit-appearance:none;
}
img
{
	border: none;
	height: auto;
	max-width: 100%;
	outline: none;
	vertical-align: middle;
}
iframe
{
    vertical-align: middle;
}
a:hover
{
	text-decoration: none;
    color:red;
}

.clear
{
	clear: both;
}
ul::after
{
	clear: both;
	content: "";
	display: block;
}
ul
{
	list-style:none;
}

body
{
	margin: 0;
	padding: 0;
	font-size: 16px;
	line-height: 27px;
	color: #474646;
    -webkit-font-smoothing: antialiased;
    font-family: 'Mulish' !important;
    
}
.overflow-x{
  overflow-x: hidden;
}
.form-label {
  color: #000;
}
.form-control {
  padding-left: 20px;
}
.header {
  position: fixed;
  width: 100%;
  z-index: 1024;
}
.header.scrolled {
  background: linear-gradient(125deg,#ffffff,#f6c6a9);
  box-shadow: 0 13px 35px -12px rgb(35 35 35 / 10%);
}
button.sign-in-btn{
    border-radius: 33px;
    border: 2px solid #FFF;
    background: #FFF;
    font-size: 16px;
    font-weight: 700;
    padding: 8px 30px;
}
button.sign-up-btn{
    border-radius: 33px;
    border: 2px solid #FFF;
    background: none;
    font-size: 16px;
    font-weight: 700;
    padding: 8px 30px;
    color: #fff !important;
}
.registration-area {
    background: url("../images/hero-banner.png");
    background-repeat: no-repeat;
    height: 100vh;
    background-position: right;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dot-vector {
  position: absolute;
  top: 0;
  right: 47%;
}

.registration-left h4 {
    color: #000;
    font-size: 38px;
    font-weight: 800;
    position: relative;
    padding-left: 20px;
}
.registration-left h4:after {
    content: "";
    position: absolute;
    width: 5px;
    height: 100%;
    background: #ee6f22;
    left: 0;
}
.registration-left form {
    margin-top: 60px;
}
.registration-left .form-control {
    height: 50px;
    border-radius: 40px;
    border: 1px solid #00000029;
}
.form-control::placeholder {
    font-size: 14px;
    color: #00000070;
}
.registration-left .form-label {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 2px;
  color: #000;
}
.registration-left .form-check-input {
    border: 1px solid rgb(238 111 34);
}
.registration-left label.form-check-label {
    font-size: 14px;
    font-weight: 400;
}
.registration-left button {
    background: #ee6f22;
    color: #fff !important;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 11px 70px;
    border-radius: 25px;
    margin: 70px auto 0;
    display: flex;
    flex-wrap: nowrap;
}
.css-13cymwt-control {
    height: 50px !important;
    border: 1px solid #00000029 !important;
    border-radius: 30px !important;
}
.css-1u9des2-indicatorSeparator {
    display: none !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;

}
.form-control:focus {
    box-shadow: unset !important;
}
.css-d7l1ni-option {
    -webkit-tap-highlight-color: #fff;
    color: #ffff !important;
    background-color: #ee6f22 !important;
    padding: 10px 12px !important;
}
.css-1n6sfyn-MenuList {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}
.css-1nmdiq5-menu {
    border-radius: 18px !important;
    overflow: hidden !important;
}
.css-10wo9uf-option {
    padding: 10px 12px !important;
}
.btn-check:focus+.btn, .btn:focus {
    box-shadow: none !important;
}
.success-box .modal-header {
    justify-content: center;
    border-bottom: none;
}
.success-box .modal-body {
    text-align: center;
}
.success-box .modal-body h5 {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 18px;
}
.success-box .modal-body p {
    font-size: 16px;
    color: #000;
    padding: 0 10px;
}
.success-box .modal-footer {
    border-top: none;
    justify-content: center;
}
.success-box .modal-footer button {
    background: #FE7733 !important;
    border: none;
    text-transform: uppercase;
    padding: 11px 45px;
    border-radius: 40px;
    font-weight: 700;
}

.success-box .modal-content {
    border-radius: 50px;
    padding: 10px 35px;
    border: 2px solid #FE7733;
    background: url("../images/modal-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    background-color: #fff;
}
.success-box .modal-content {
  width: 95%;
}
.success-box .modal-dialog {
  justify-content: center;
}
a.forgot-password-area {
    color: #EE6F22 !important;
    text-decoration: none;
    font-weight: 700;
    text-align: right;
    padding-top: 3px;
    cursor: pointer;
    display: flex;
    justify-content: end;
}
p.bottom-text span {
    color: #ee6f22;
    font-weight: 800;
    cursor: pointer;
}

p.bottom-text {
    text-align: center;
    margin-top: 15px;
    font-size: 17px;
    font-weight: 600;
}
p.password-reset {
    color: #000;
    font-weight: 600;
    margin-top: 12px;
}
.dashboard-area {
    padding-top: 120px;
}
.dashboard-tab-area ul li button {
  background: #FBDFCE !important;
  border-radius: 20px !important;
  height: 60px;
  padding: 0 25px;
  border: none !important;
}
.dashboard-tab-area ul li button {
  background: #FBDFCE !important;
  border-radius: 20px !important;
  height: 60px;
  padding: 0 20px;
  border: none !important;
  color: #000 !important;
  font-weight: 600;
  font-size: 17px;
}
.dashboard-tab-area ul {
  border-bottom: none;
}
.dashboard-tab-area ul li {
  margin-right: 7px;
}
.form-check-input:checked {
  background-color: #ee6f22;
  border-color: #ee6f22;
}
.create-profile-area {
  padding-top: 110px;
}
.create-profile-content-area {
  background: #FBDFCE;
  border-radius: 50px;
  padding: 30px;
  min-height: 840px;
  position: relative;
}
.create-profile-content-area:after {
  position: absolute;
  content: "";
  background: none;
  width: 200px;
  height: 200px;
  top: -8px;
  border: 2px dashed #ee6f22;
  right: -8px;
  border-top-right-radius: 50px;
  z-index: -1;
}
.create-profile-content-area:before {
  position: absolute;
  content: "";
  background: none;
  width: 200px;
  height: 200px;
  bottom: -8px;
  border: 2px dashed #ee6f22;
  left: -8px;
  border-bottom-left-radius: 50px;
  z-index: -1;
}
.create-profile-content-area .StepButton-d2-0-2-8.active:after {
  position: absolute;
  content: "";
  width: 62px;
  height: 62px;
  background: none;
  border-radius: 70px;
  border: 1px solid #ee6f22;
}

.create-profile-content-area .StepButton-d2-0-2-8.active {
  background-color: #ee6f22 !important;
  width: 50px;
  height: 50px;
}
.create-profile-content-area .StepButtonContent-d3-0-2-9.active {
  color: rgb(255, 255, 255);
  font-size: 25px;
  font-weight: 700;
}
span.StepButtonContent-0-2-5.StepButtonContent-d7-0-2-13 {
  color: rgb(255, 255, 255);
  font-size: 25px;
  font-weight: 700;
}
.StepButtonContent-0-2-5 {
  font-size: 20px !important;
  font-weight: 900;
}
span.StepButtonContent-0-2-5.StepButtonContent-d3-0-2-9.completed {
  color: rgb(255, 255, 255);
  font-size: 25px;
  font-weight: 700;
}
span.StepButtonContent-0-2-5.StepButtonContent-d11-0-2-23 {
  color: rgb(255, 255, 255);
  font-size: 25px;
  font-weight: 700;
}
.create-profile-content-area .StepButton-d2-0-2-8.completed, .StepButton-d6-0-2-12.completed, .StepButton-d10-0-2-22.active {
  background-color: #ee6f22 !important;
  width: 50px;
  height: 50px; 
}
.create-profile-content-area  .StepButton-d2-0-2-8.completed:after {
  position: absolute;
  content: "";
  width: 62px;
  height: 62px;
  background: none;
  border-radius: 70px;
  border: 1px solid #ee6f22;
}
.ConnectorContainer-d6-0-2-26, .ConnectorContainer-d2-0-2-18, .ConnectorContainer-d2-0-2-24, .ConnectorContainer-d6-0-2-36 {
  top: 24px !important;
  left: calc((-50% + 2em) - 2px) !important;
  right: calc((50% + 2em) - 2px) !important;
  position: absolute;
}
.Connector-d7-0-2-27, .Connector-d3-0-2-19{
  display: block;
  border-color: rgb(238 111 34) !important;
  border-top-style: solid;
  border-top-width: 3px !important;
}
.Connector-d7-0-2-37, .Connector-d3-0-2-25 {
  display: block;
  border-color: rgb(238 111 34) !important;
  border-top-style: solid;
  border-top-width: 3px !important;
}
.create-profile-content-area .StepButton-d10-0-2-22 {
  width: 50px;
  border: none;
  cursor: pointer;
  height: 50px;
  margin: 0px;
  display: flex;
  padding: 0px;
  font-size: 1em;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background-color:  rgb(238 111 34 / 54%);
}
.StepButton-d6-0-2-18, .StepButton-d10-0-2-32 {
  width: 50px !important;
  border: none;
  cursor: pointer;
  height: 50px !important ;
  margin: 0px;
  display: flex;
  padding: 0px;
  font-size: 1em;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background-color: rgb(238 111 34 / 54%) !important;
  position: relative;
}
.StepButton-d6-0-2-18.active, .StepButton-d6-0-2-18.completed, .StepButton-d10-0-2-32.active {
  background-color: rgb(238 111 34) !important;
}
span.StepButtonContent-0-2-5.StepButtonContent-d7-0-2-19, .StepButtonContent-d11-0-2-33.active {
  color: rgb(255, 255, 255);
  font-size: 25px;
  font-weight: 700;
}
.StepButton-d6-0-2-18:after, .StepButton-d10-0-2-32:after {
  position: absolute;
  content: "";
  width: 62px;
  height: 62px;
  background: none;
  border-radius: 70px;
  border: 1px solid  rgb(238 111 34 / 54%);
}
.create-profile-content-area .StepButton-d10-0-2-22::after {
  position: absolute;
  content: "";
  width: 62px;
  height: 62px;
  background: none;
  border-radius: 70px;
  border: 1px solid  rgb(238 111 34 / 54%);
}
.create-profile-content-area .StepButton-d6-0-2-12.active {
  background-color: rgb(238 111 34);
}
.create-profile-content-area .StepButton-d6-0-2-12 {
  width: 2em;
  border: none;
  cursor: pointer;
  height: 2em;
  margin: 0px;
  display: flex;
  padding: 0px;
  font-size: 1em;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background-color: rgb(238 111 34 / 54%);
  width: 50px;
  height: 50px;
  position: relative;
}
.create-profile-content-area .StepButton-d6-0-2-12:after {
  position: absolute;
  content: "";
  width: 62px;
  height: 62px;
  background: none;
  border-radius: 70px;
  border: 1px solid rgb(238 111 34 / 54%);
}
.stepper-form-area form label {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 2px;
}
.stepper-form-area .form-control {
  height: 50px;
  border-radius: 40px;
  border: 1px solid #00000029;
  background: none;
}
input.upload-item {
  position: absolute;
  width: 586px;
  top: 29px;
  opacity: 0;
}
.upload-area {
  position: relative;
}
.upload-area i {
  position: absolute;
  right: 18px;
  bottom: 16px;
  color: #808080c2;
  font-size: 18px;
}
.stepper-form-area .col-md-6 {
  padding: 0 20px;
}
button.next-btn {
  background: #ee6f22;
  color: #ffff;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 100px;
  border-radius: 32px;
  display: flex;
}
button.skip-btn {
  background: #ffffff;
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 100px;
  border-radius: 32px;
  display: flex;
  margin: 0 12px;
}
.skip-next-btn {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.create-profile-area {
  padding: 125px 0 70px 0;
}
button.submit-btn {
  background: #ee6f22;
  color: #ffff;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 100px;
  border-radius: 32px;
  display: flex;
  margin: 80px auto 0;
}
.stepper-form-area {
  min-height: 520px;
  padding-top: 20px;
}
.profile-header button.sign-in-btn {
  border-radius: 33px;
  background: #ee6f22;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 30px;
  border: 2px solid #ee6f22;
  color: #fff;
}
.profile-header button.sign-up-btn {
  border-radius: 33px;
  border: 2px solid #ee6f22;
  background: none;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 30px;
  color: #ee6f22 !important;
}
.Label-0-2-11 {
  color: #ee6f22;
  font-size: 16px;
  font-weight: 600 !important;
}
button.nav-link.active img {
  filter: invert(1);
}
.dashboard-tab-area button.nav-link.active {
  background: #ee6f22 !important;
  color: #fff !important;
}
.nav-link img {
  margin-right: 7px;
}
.dashboard-hero-banner-area {
background-image: url("../images/w-dashboard.png");
background-repeat: no-repeat;
background-size: cover;
height: 100%;
border-radius: 26px;
}
.loader-area {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #00000087;
  z-index: 999;
  top: 0;
  left: 0;
  z-index: 1024;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.dashboard-area-left h1 {
  font-size: 45px;
  color: #000;
  font-weight: 900;
  text-transform: uppercase;
}
.dashboard-area-left h1 span {
  color: #EE6F22;
}
.dashboard-area-right button {
  display: block;
  margin-left: auto;
}
.dashboard-hero-banner-area .row {
  align-items: center;
}
button.find-insurance-btn {
  background: #EE6F22;
  width: 200px;
  min-width: 200px;
  padding: 9px 10px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 90px;
  color: #fff !important;
  cursor: pointer;
}
button.active-request {
  background: #000000;
  width: 200px;
  min-width: 200px;
  padding: 9px 10px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 90px;
  color: #fff !important;
  margin: 15px 0 15px auto;
  cursor: pointer;
}
.recent-loads-top-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 25px 0;
}
.recent-loads-area {
  padding: 25px 0 40px;
}
.recent-loads-top-area h3 span {
  color: #ee6f22;
}
.recent-loads-top-area h3 {
  font-size: 27px;
  color: #000;
  font-weight: 800;
}
.recent-loads-top-area button {
  border: 1px solid #ee6f22;
  color: #ee6f22 !important;
  border-radius: 92px;
  font-weight: 500;
  padding: 5px 13px;
  white-space: nowrap;
}
.recent-loads-area .swiper-slide {
  color: #fff;
  background: #ee6f22;
  padding: 15px;
  border-radius: 20px;
  min-height: 220px;
}
.upcoming-loads-content ul {
  min-height: 120px;
}
.upcoming-loads-content h6 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 6px;
}
.upcoming-loads-content ul li i {
  margin-right: 8px;
}
.upcoming-loads-content ul li {
  font-size: 14px;
  font-weight: 400;
  color: #fffc;
  padding: 3px 0;
}
.upcoming-loads-content ul li i {
  color: #ffff;
}
.upcoming-loads-content ul li span {
  margin-left: 30px;
}
.upcoming-loads-content-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
.upcoming-loads-content-bottom p {
  font-size: 18px;
  font-weight: 800;
}
.upcoming-loads-content-bottom button {
  background: #FBDFCE;
  font-size: 14px;
  font-weight: 400;
  border-radius: 29px;
  padding: 2px 13px;
}
.upcoming-loads-content-bottom button i {
  margin-left: 5px;
}
.dashboard-top-tab-area .swiper-slide {
  margin-right: 5px!important;
}
.footer {
  background-image: url("../images/footer-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0;
}
.footer-left p {
  color: #000;
  padding: 20px 0;
  width: 381px;
}
.footer-center h6 {
  text-transform: uppercase;
  color: #000;
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 15px;
}
.footer-center ul li {
  font-size: 14px;
  color: #000;
  padding: 3px 0;
  list-style: disc;
}
.footer-left ul li i {
  color: #000;
  font-size: 18px;
}
.footer-left ul {
  display: flex;
}
.footer-left ul li {
  margin-right: 18px;
}
.footer-center.footer-right ul li i {
  color: #000;
  margin-right: 8px;
}

.footer-center.footer-right ul li {
  list-style: unset;
}
.footer-bottom {
  background: #ee6f22;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 12px 0;
  text-align: center;
}
.manage-truck-area {
  background: #FADECD;
  padding: 20px;
  border-radius: 20px;
  margin: 40px 0 60px;
}
.manage-truck-top-area p {
  font-size: 15px;
}
.manage-truck-detail-box {
  border-radius: 20px;
  border: 0.5px solid #EE6F22;
  background: #FBE0CF;
  box-shadow: 0px 4px 33px 10px rgba(0, 0, 0, 0.08);
  padding: 15px;
  margin-bottom: 12px;
}
.manage-truck-detail-left h6 {
  font-size: 20px;
  color: #000;
  font-weight: 700;
  margin-bottom: 10px;
}
.manage-truck-detail-left p {
  color: rgba(0, 0, 0, 0.70);
  font-size: 14px;
}
.manage-truck-detail-left p span {
  color: #EE6F22;
  font-weight: 800;
}
.truck-detail p, .truck-detail-bottom p {
  margin-right: 40px;
}

.truck-detail, .truck-detail-bottom {
  display: flex;
  flex-wrap: wrap;
}
.truck-images {
  margin-top: 10px;
}
.truck-images img {
  width: 70px;
  height: 70px;
  min-width: 70px;
  min-height: 70px;
  object-fit: cover;
  margin-right: 7px;
  border-radius: 7px;
  margin-bottom: 7px;
}
.manage-truck-detail-right {
  display: flex;
  justify-content: end;
}
button.edit-btn {
  background: #EE6F22;
  color: #fff !important;
  padding: 0 10px;
  font-size: 15px;
  border-radius: 30px;
  width: 85px;
  height: 32px;
}
button.delete-btn {
  background:   #FF0000;
  color: #fff !important;
  padding: 0 10px;
  font-size: 15px;
  border-radius: 30px;
  width: 85px;
  height: 32px;
}
.manage-truck-detail-right button {
  margin: 0 4px;
}
.pagination-area ul.pagination {
  justify-content: center;
}
.pagination-area {
  margin: 50px 0 30px;
}
.pagination-area .page-link {
  background: none;
  border: 1px solid #000;
  color: #000;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-weight: 600;
}
.pagination-area .page-item.active .page-link {
  background: none;
  color: #ee6f22;
  border: 1px solid #ee6f22;
}
.pagination-area .page-item.disabled .page-link {
  color: #00000078;
  pointer-events: none;
  background-color: unset !important;
  border-color: #00000078;
}
.page-link:focus {
  box-shadow: unset !important;
}
.add-truck-area a {
  font-size: 17px;
  color: #000;
  font-weight: 600;
  text-decoration: none;
}
.add-truck-area a span {
  color: #ee6f22;
}
.add-truck-area h3 {
margin-top: 20px;
  font-size: 27px;
  color: #000;
  font-weight: 800;
} 
.add-truck-area p {
  color: #000;
}
button.addtruck-btn {
  background: #ee6f22;
  text-transform: uppercase;
  color: #fff !important;
  font-weight: 600;
  font-size: 14px;
  padding: 7px 25px;
  border-radius: 60px;
  margin: 50px 0 20px;
}
.capacity-area {
  position: relative;
}
.capacity-area .dropdown button {
  background: none !important;
  border: none !important;
  color: #000 !important;
}
.capacity-area .dropdown {
  position: absolute;
  top: 35px;
  right: 6px;
  border-left: 1px solid #d2baac;
}
.first-preview-image i {
  color: gray;
}
.first-preview-image {
  width: 50px;
  height: 50px;
  background: #80808061;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 12px;
  position: relative;
}
.upload-image-preview {
  display: flex;
  flex-wrap: wrap;
}
.swiper-button-prev:after, .swiper-button-next:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  /* width: 32px; */
  padding: 22px;
  font-size: 16px;
  font-weight: 900;
  color: #000;
  vertical-align: middle;
  text-align: center;
}
.swiper-button-prev, .swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: 33px;
  height: 33px;
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  background: #FBDFCE;
  border-radius: 32px;
  padding-left: 3px !important;
}
.my-profile-area {
  padding-top: 70px;
}
.my-profile-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #7a7a7a1f;
  padding-bottom: 18px;
}
.my-profile-top h3 {
  font-size: 27px;
  font-weight: 800;
  color: #000;
}
button.change-password-btn {
  background: #ee6f22;
  color: #fff !important;
  border-radius: 70px;
  margin-right: 10px;
  padding: 7px 18px;
}
button.edit-profile-btn {
    border: 1px solid #ee6f22;
    color: #ee6f22 !important;
    border-radius: 70px;
      padding: 7px 18px;
}
.trucker-image-area {
  justify-content: center;
  display: flex;
  margin: 20px auto 20px;
  width: 200px;
  position: relative;
}
.trucker-image-area img {
  width: 200px;
  height: 200px;
  min-width: 200px;
  min-height: 200px;
  border-radius: 50%;
  border: 1px solid #ee6f22;
  object-fit: cover;
}
.trucker-info-detail p {
  color: #000;
  font-size: 18px;
  margin-bottom: 6px;
}
.trucker-info-detail h6 {
  color: #ee6f22;
  font-size: 18px;
  font-weight: 700;
}
.trucker-info-detail {
    margin-bottom: 35px;
}
.my-profile-area .manage-truck-area {
  padding: 20px 40px;
}
.auth-header a.nav-link {
  color: #000 !important;
  font-size: 15px;
  margin: 0 25px;
}
.notification-top span {
  position: absolute;
  top: 1px;
  right: 20px;
  font-size: 9px;
  background: #ee6f22;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  min-width: 18px;
  min-height: 18px;
  border-radius: 50%;
}
.profile-header-top {
  display: flex;
  align-items: center;
}
.notification-top {
  position: relative;
}
.profile-header-top img {
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  object-fit: cover;
  border-radius: 50%;
}
.profile-header-top a.nav-link {
  margin: 0;
}
.profile-header-top {
  display: flex;
  border: 1px solid #EE6F22;
  padding: 0 7px;
  border-radius: 140px;
  margin-left: 15px;
  height: 45px;
}
.profile-header-top .nav-item.show.dropdown {
  height: 45px;
  width: fit-content;
  min-width: 80px;
}
a#basic-nav-dropdown {
  color: #ee6f22 !important;
}
.profile-header-top .dropdown-menu.show {
  left: -40px;
  border-radius: 20px;
  border: 0.5px solid #EE6F22;
  background: #FFF;
  margin-top: 8px;
  padding: 0;
  overflow: hidden;
}
a.dropdown-item:hover {
  background: #ee6f22;
  color: #fff !important;
}
a.dropdown-item {
  padding: 10px 15px;
  color: #000 !important;
}
.capacity-select-area {
  position: absolute;
  right: 0;
  top: 29px;
}
.trash-box .modal-content {
  width: 80%;
}
.trash-box .modal-dialog {
  justify-content: center;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
:root, .css-d7l1ni-option:active {
  --swiper-theme-color: #ee6f22 !important;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.errors {
  color: red !important;
  font-size: 14px !important;
  text-align: start;
}
.first-preview-image img {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  object-fit: cover;
  border-radius: 5px;
}
.first-preview-image i.fa.fa-trash {
  position: absolute;
  color: red;
  background: #fff;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 13px;
  top: -7px;
  right: -6px;
  box-shadow: 0px 4px 33px 10px rgba(0, 0, 0, 0.08);
}
.trash-box button.trash-no-btn {
  background: none !important;
  border: 2px solid #FE7733;
  color: #FE7733;
}
.change-password-box .modal-content {
  width: 100%;
  padding: 10px 20px;
}
.change-password-box .modal-header {
  justify-content: left;

}
.change-password-content form {
  margin-top: 0 !important;
}
.change-password-content label.form-label {
  text-align: left;
  display: flex;
}
button.edit-profile-update-btn {
  background: #ee6f22;
  color: #fff !important;
  border-radius: 70px;
  padding: 10px 80px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 70px auto 10px;
  display: flex;
}
.profile-edit-area i {
  position: absolute;
  bottom: 5px;
  right: 20px;
  color: #EE6F22;
  background: #FBE0CF;
  border: 2px solid #EE6F22;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 21px;
}
.profile-edit-area input {
  bottom: 5px;
  position: absolute;
  right: 19px;
  width: 42px;
  height: 42px;
  opacity: 0;
}
.dashboard-top-tab-area .swiper-slide button {
  background: #fbe0cf;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 58px;
  padding: 0 15px;
}
.dashboard-top-tab-area .swiper-slide button img {
  margin-right: 6px;
}
.dashboard-top-tab-area .swiper-slide {
  background: #fbe0cf;
  border-radius: 20px;
  overflow: hidden;
}

.dashboard-top-tab-area .active button, .dashboard-top-tab-area .active {
  background: #ee6f22 !important;
  color: #fff;
}
.dashboard-top-tab-area .active button img {
  filter: invert();
}
.dashboard-top-tab-area {
  margin-bottom: 30px;
  position: relative;
}
.dashboard-top-tab-area .swiper-slide {
  /* width: 280px !important; */
}
.coming-soon-page {
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.coming-soon-page-content h2 {
  font-size: 40px;
  color: #000;
  font-weight: 900;
}

.coming-soon-page-content {
  text-align: center;
}
.coming-soon-page-content button {
  background: #ee6f22;
  color: #fff !important;
  margin-top: 30px;
  padding: 10px 30px;
  border-radius: 110px;
}
.coming-soon-page-content h2 {
  font-size: 50px;
  font-weight: 900;
  color: #000;
} 
.coming-soon-page-content p {
  color: #000;
  font-size: 17px;
  padding: 6px 0;
}
button.tab-right-arrow-btn {
  position: absolute;
  right: 8px;
  bottom: 16px;
  z-index: 1;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #ee6f22;
  box-shadow: 0px 4px 33px 10px rgb(0 0 0 / 19%);
}
button.tab-left-arrow-btn {
  position: absolute;
  left: 8px;
  bottom: 16px;
  z-index: 1;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #fbdfce;
  box-shadow: 0px 4px 33px 10px rgb(0 0 0 / 19%);
}
button.tab-left-arrow-btn i {
  font-size: 20px;
  color: #000;
}
button.tab-right-arrow-btn i {
  font-size: 20px;
  color: #fff;
}
.main-home-area {
  padding-top: 90px;
}
/* .main-home-banner-area {
  background: url("../images/main-home-banner.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 495px;
  min-height: 495px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
} */
/* .main-home-banner-area.area_12 {
  background: url("../images/main-home-banner-1.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 565px;
}
.main-home-banner-area.area_13 {
  background: url("../images/main-home-banner-2.png");
  background-size: cover;
  width: 100%;
  height: 565px;
}
.main-home-banner-area.area_14 {
  background: url("../images/main-home-banner-3.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 565px;
  
} */
.main-banner-left h5 {
  font-size: 22px;
  text-transform: uppercase;
  color: #ffff;
  font-weight: 900;

}
.main-banner-left h1 {
  color: #FBE0CF;
  text-transform: uppercase;
  font-size: 95px;
  font-weight: 900;
  margin-bottom: 5px;
}
.main-banner-left p {
  color: #fff;
  line-height: 30px;
}
.about-distrix-heading h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  color: #000;
  font-size: 37px;
  font-weight: 800;
  padding: 2px 15px;
}
.about-distrix-heading {
  background: rgba(238, 111, 34, 0.20);
  height: 3px;
  position: relative;
}
.about-distrix-area {
  padding: 40px 0 30px;
}
.distrix-content-bg-area {
  background-image: url("../images/distrix.png");
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  padding: 50px 100px 40px;
}
.distrix-content-bg-area p {
  font-size: 14px;
  margin-bottom: 12px;
}
.third-area-left h2 span {
  color: #ee6f22;
}
.third-area-left h2 {
  font-size: 40px;
  color: #000;
  font-weight: 800;
  margin-bottom: 20px;
  line-height: 48px;
}
.third-area-left p {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 10px;
  color: #000;
}
.sales-manage-order {
  background: #FBE0CF;
  padding: 50px 0;
}
.main-home-third-area {
  padding: 60px 0 70px;
}
.sales-mage-order-right ul li {
  font-size: 15px;
  padding: 7px 0;
}

.sales-mage-order-right ul {
  margin-top: 7px;
}
.sales-mage-order-right ul li i {
  font-size: 17px;
  margin-right: 5px;
}
.feature-benefits-area {
  background: rgba(238, 111, 34, 0.20);
  height: 3px;
  position: relative;
}
.feature-benefits-area h2 {
  color: #000;
  font-size: 37px;
  display: inline;
  font-weight: 800;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  background: #fff;
  padding: 0 15px;
}
.features-and-benefits {
  padding-bottom: 70px;
}
.feature-benefits-area-left {
  background: #EE6F22;
  border-radius: 26px;
  padding: 25px;
  height: 100%;
  color: #fff;
}
.feature-benefits-area-left h6 {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 20px;
}
.feature-benefits-area-left ul li b {
  font-size: 16px;
  font-weight: 800;
}
.feature-benefits-area-left ul li {
  font-size: 15px;
  padding: 12px 0;
  font-weight: 300;
}
.features-and-benefits .row {
  margin-top: 55px;
}
.feature-benefits-content-area {
  display: flex;
  margin: 40px 0;
  min-height: 120px;
}
.feature-benefits-icon-left {
  min-width: 70px;
}
.feature-benefits-content-right h5 {
  font-size: 22px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 8px;
}
.feature-benefits-content-right p {
  color: rgba(255, 255, 255, 0.50);
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
}
.feature-benefits-area-right {
  border: 1px solid #EE6F22;
  color: #000;
  background: none;
}
.feature-benefits-area-right img {
  filter: invert(1);
}
.feature-benefits-area-right h5 {
  color: #000;
}
.feature-benefits-area-right p {
  color: rgba(0, 0, 0, 0.50);
}
.orange-btns button.sign-in-btn {
  background: #ee6f22;
  color: #fff;
  border: 1px solid #ee6f22;
}
.orange-btns button.sign-up-btn {
  border: 2px solid #ee6f22;
  color:#ee6f22 !important;
}
.truck-logo-upload-field input {
  width: 50px;
  height: 50px;
  min-height:50px;
  border-radius: 5px !important;
  background: #a9a8a8 !important;
  border: none !important;
}
.logo-upload-box {
  width: 50px;
  position: relative;
  margin-right: 8px;
}
.logo-upload-box i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: lightgray;
}
.upload-box-area {
  display: flex;
}
.logo-upload-box input.upload-item {
  position: absolute;
  width: 586px;
  top: 0;
  opacity: 0;
}
.logout-btn-top {
  background: #ee6f22 !important;
  color: #fff !important;
  text-transform: uppercase;
  padding: 11px 32px !important;
  border: none !important;
}
.registration-left {
  padding-right: 80px;
}
.StepButtonContent-d1-0-2-7.active {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 900;
}
.Label-d1-0-2-11 {
  display: block;
  margin-top: 16px;
  text-align: center;
  font-weight: 500;
  color: #ee6f22;
  font-size: 16px;
  font-weight: 500;
}
.StepButton-d0-0-2-6.active:after {
  content: "";
  position: absolute;
  width: 62px;
  height: 62px;
  background: no-repeat;
  border-radius: 50%;
  border: 1px solid #ee6f22;
}
.StepButton-0-2-4.active {
  background-color: #ee6f22 !important;
  width: 50px;
  height: 50px;
  position: relative;
}
.StepButton-0-2-4:after {
  content: "";
  position: absolute;
  width: 62px;
  height: 62px;
  background: none;
  border-radius: 50%;
  border: 1px solid #ee6f227d;
}
.StepButton-0-2-4 {
  width: 50px !important;
  height: 50px !important;
  background: #ee6f227d!important;
  position: relative;
}
.Label-0-2-9 {
  color: #ee6f22bd;
  font-size: 16px;
}
.StepButton-0-2-4:after {
    content: "";
    position: absolute;
    width: 62px;
    height: 62px;
    background: none;
    border-radius: 50%;
    border: 1px solid #ee6f227d;
}

.StepButtonContent-d3-0-2-13 {
  font-size: 20px;
  font-weight: 900;
  color: #ffffffc4;
}
.Label-d3-0-2-19 {
  display: block;
  margin-top: 16px;
  text-align: center;
  font-weight: 500;
  color: #ee6f22bd;
}
.Connector-0-2-15 {
  border-color: #ee6f22 !important;
  border-top-width: 3px !important;
}
.ConnectorContainer-0-2-14 {
  top: calc((2em - -15px) / 2) !important;
  left: calc((-50% + 38px) - 8px) !important;
  right: calc((50% + 38px) - 8px) !important;
}
.error-page {
  background: #FBDFCE;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.error-page-content h2 {
  font-size: 80px;
  color: #EE6F22;
  font-weight: 900;
  margin-bottom: 30px;
}
.error-page-content h5 {
  color: #000;
  font-size: 22px;
  font-weight: 900;
  margin-bottom: 6px;
}
.error-page-content p {
  color: #000;
  font-size: 16px;
  margin-bottom: 40px;
}
.error-page-content a {
  color: #fff;
  text-decoration: unset;
  background: #ee6f22;
  border-radius: 140px;
  padding: 12px 22px;
  font-weight: 700;
}
.truck-photos-area i {
  position: absolute;
  bottom: 15px;
  right: 22px;
}

.truck-photos-area {
  position: relative;
} 
.w-dashboard-area {
  background-image: url("../images/w-dashboard.png");
  background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    border-radius: 26px;
} 
.w-dashboard-area .dashboard-area-left h1 {
  font-size: 33px;
  line-height: 45px;
}
.dashboard-area-left {
  padding-left: 35px;
}
.dashboard-area-right {
  padding: 0 30px;
}
.product-management-area {
  display: flex;
  justify-content: space-between;
  align-items:baseline;
  margin-bottom: 18px;
}
.product-management-top h3 span {
    color: #ee6f22;
}
.product-management-top h3 {
    font-size: 27px;
    color: #000;
    font-weight: 800;
    margin-bottom: 2px;
}
.product-management-top p {
  color: rgba(0, 0, 0, 0.70);
  font-size: 14px;
}
.product-management-area button {
  font-size: 15px;
  color: #ee6f22 !important;
  border: 1px solid #ee6f22;
  border-radius: 70px;
  padding: 5px 12px;
}
.product-management-area button a {
  color: #ee6f22 !important;
  text-decoration: unset;
}
.add-new-product h3 {
  margin-top: 0;
}
.upload-photo-top-area .logo-upload-box i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
}
.upload-photo-top-area .logo-upload-box input {
  width: 100px;
  height: 100px;
}
.upload-photo-top-area .logo-upload-box {
  position: relative;
  width: 100px;
  margin-right: 8px;
  height: 100px;
}
.product-images-area img {
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  object-fit: cover;
  border-radius: 5px;
}
.product-images-area {
  width: 100px;
  height: 100px;
  background: #80808061;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 12px;
  position: relative;
}
.product-listing-top-area {
  padding: 110px 0 35px;
}
.product-listing-banner-area {
  background: #FBDFCE;
  padding: 20px;
  min-height: 400px;
  border-radius: 26px;
}
.product-listing-banner-area {
  background: #FBDFCE;
  padding: 40px;
  border-radius: 26px;
}
.product-listing-banner-area-left h2 {
  font-size: 42px;
  text-transform: uppercase;
  color: #000;
  font-weight: 900;
  line-height: 52px;
  margin-bottom: 10px;
}
.product-listing-banner-area-left h2 span {
  color: #ee6f22;
}
.product-listing-banner-area-left p {
  font-size: 15px;
}
.filter-search-area-left {
  display: flex;
  align-items: flex-end;
}
h6.filter-text {
  font-size: 17px;
  color: #000;
  font-weight: 600;
  margin-bottom: 6px;
}
.products-listing-area .css-13cymwt-control {
  height: 50px !important;
  background: #EE6F22;
  border: unset !important;
  width: 185px;
  border-radius: 15px !important;
  color: #fff !important;
}
.products-listing-area .css-1xc3v61-indicatorContainer {
  color: hsl(0deg 0% 100%) !important;
}
.products-listing-area .css-1jqq78o-placeholder {
  color: hsl(0deg 0% 100%) !important;
  font-size: 14px;
}
.search-area input {
  height: 50px;
  background: #ee6f22;
  border: unset;
  color: #fff;
  border-radius: 15px ;
  width: 320px;
}
.search-area input::placeholder {
  color: #fff;
}
.sub-category {
  margin: 0 8px;
}
.search-area .form-control:focus {
  background-color: #ee6f22 !important;
  color: #fff;
}
.search-area img {
  position: absolute;
  right: 17px;
  bottom: 14px;
  width: 22px;
  color: #fff;
}
.search-area {
  position: relative;
}
.product-listing-content-area {
  background: #FBDFCE;
  padding: 12px;
  border-radius: 26px;
  height: 100%;
  margin-bottom: 23px;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  cursor: pointer;
}
.product-listing-content-area:hover {
  -webkit-transform: scale(1.08);
  transform: scale(1.04);
}
.product-listing-content-area img {
  width: 100%;
  height: 240px;
  min-height: 240px;
  object-fit: contain;
  border-radius: 26px;
  background: #f0f0f0;
}
.product-detail h5 {
  font-size: 20px;
  color: #000;
  font-weight: 700;
  margin: 15px 0 6px;
}
.product-detail p span {
  color: #ee6f22;
  font-size: 15px;
  font-weight: 800;
}

.product-detail p {
  font-size: 13px;
}
.product-detail p {
  font-size: 13px;
  color: #000;
}
.product-detail h6 {
  color: #ee6f22;
  font-weight: 800;
  margin: 10px 0;
}
.product-detail button {
  filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.25));
  background: #ee6f22;
  color: #fff !important;
  border-radius: 140px;
  font-size: 14px;
  font-weight: 200;
  padding: 4px 15px;
  margin: 12px 0 0;
}
.pagination-area ul li {
  margin-right: 7px;
}
.prodiuct-detail-box p {
  font-size: 18px;
  color: #000;
  font-weight: 600;
}
.prodiuct-detail-box p span {
  color: #ee6f22;
  font-weight: 700;
}
.product-image-left img {
  width: 100%;
  height: 500px;
  min-height: 500px;
  object-fit: contain;
  border-radius: 26px;
  background: #d3d3d357;
}
.product-detail-right h6 {
  font-size: 20px;
  text-transform: uppercase;
  color: #000;
  font-weight: 700;
  margin-bottom: 10px;
}
.product-detail-right h3 {
  color: #000;
  font-size: 46px;
  font-weight: 700;
  margin-bottom: 10px;
}
.product-availability-order p span {
  color: #ee6f22 !important;
  font-weight: 700 !important;
  margin-right: 0 !important;
}
.product-availability-order p {
  font-size: 17px;
  color: #000;
  margin-right: 30px;
  margin-bottom: 0 !important;
}
.product-availability-order {
  display: flex;
  margin-bottom: 14px;
  flex-wrap: wrap;
}
.product-detail-right h4 span {
  font-size: 15px;
}
.product-detail-right h4 {
  color: #ee6f22;
  font-weight: 800;
  margin-bottom: 10px;
}
.product-detail-right p span {
  font-weight: 700;
  color: #ee6f22;
  margin-right: 30px;
}
.product-detail-right p {
  font-size: 16px;
  color: #000;
  margin-bottom: 10px;
}
.product-detail-right button {
  background: #ee6f22;
  color: #fff !important;
  padding: 8px 30px;
  border-radius: 140px;
  text-transform: capitalize;
  font-weight: 500;
  margin: 0 6px 0 0;
}

.product-multiple-images .product-images img {
  width: 180px !important;
  height: 180px !important;
  object-fit: contain;
    border-radius: 26px;
    background: #f0f0f0;
}
.product-multiple-images {
  display: flex;
  flex-wrap: wrap;
  margin: 25px 0 80px;
}
.product-images {
  margin-right: 10px;
  margin-top: 20px;
}
/* quantity-css-start */
form.product-quantity {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
form.product-quantity p {
  margin-bottom: 0;
}
button {
  padding: 0;
  margin: 0;
  border-style: none;
  touch-action: manipulation;
  display: inline-block;
  border: none;
  background: none;
  cursor: pointer;
}
form.product-quantity h6 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 500;
  margin-right: 15px;
  margin-bottom: 0;
}
.qty {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
}
.qty label {
  flex: 1 0 100%;
}
.qty input {
  color: #ee6f22;
  width: 50px;
  height: 47px;
  font-size: 18px;
  text-align: center;
  border: none;
  font-weight: 700;
}
.qty button {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ee6f22;
  border-radius: 0;
  padding: 0;
  font-size: 24px;
  color: #ffffff !important;
  font-weight: 700;
}
.qty button.qtyminus {
  margin-right: 0.3rem;
}
.qty button.qtyplus {
  margin-left: 0.3rem;
}
/* quantity-css-end */
.add-new-product a {
  line-height: 40px;
}
.product-images img {
  width: 70px;
  height: 70px;
  border-radius: 5px;
  object-fit: cover;
}

.checkout-area {
  padding: 100px 0 50px 0;
  min-height: 590px;
}
h2.checkout-heading {
  text-align: center;
  text-transform: uppercase;
  font-size: 37px;
  color: #000;
  font-weight: 900;
  margin-bottom: 33px;
}
.checkout-left-area .accordion {
  border-radius: 20px;
  overflow: hidden;
}
.accordion-item {
  border-radius: 20px !important;
  border: 1px solid #EE6F22;
  background: #FBE0CF;
  box-shadow: 20px 32px 27px 0px rgba(0, 0, 0, 0.02);
  overflow: hidden;
  margin-bottom: 12px;
}
.checkout-left-area .accordion-button {
  color: #000000;
  background-color: #FBE0CF !important;
  box-shadow: none;
  display: block;
}
.checkout-left-area .accordion-body {
  background: #fbdfce;
}
.delivery-address-area {
  background: #fff;
  padding: 13px;
  border-radius: 110px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-bottom: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.checkout-left-area h2.accordion-header button h6 {
  font-size: 18px;
  color: #000;
  font-weight: 700;
}
.checkout-left-area h2.accordion-header button p {
  font-weight: 500;
  color: #000;
  margin-top: 10px;
  line-height: 18px;
  font-size: 14px;
}
.form-check-input {
  width: 22px;
  height: 22px;
  border: 2px solid rgb(238 111 34);
  margin: 0  12px 0;
  min-width: 22px;
  min-height: 22px;
}
.delivery-address-area .form-check {
    display: flex;
    align-items: center;
}
.add-address-area .form-control::placeholder {
  color: #000;
}
.add-address-area .form-control {
  height: 45px;
  border: 1px solid #00000026;
  border-radius: 110px;
  background: #ffffff0d;
}
.add-address-area .form-check {
  display: flex;
  align-items: center;
}
.add-address-area .form-check-input {
  background: none;
  border: 1px solid #ee6f22;
  width: 20px;
  height: 20px;
  min-width: 20px;
}
.add-address-area button {
  background: #ee6f22;
  width: 200px;
  color: #fff !important;
  font-size: 16px;
  font-weight: 700;
  padding: 9px;
  border-radius: 80px;
  margin: 10px;
}
.credit-cards-area {
  background: #fff;
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 30px;
  position: relative;
}
.selected-check img {
  position: absolute;
  right: -3px;
  top: -10px;
}
.credit-cards-area p span {
  margin-right: 10px;
}
.credit-cards-area p {
  font-size: 17px;
  font-weight: 900;
  justify-content: space-around;
  color: #000;
  display: flex;
  align-items: center;
}
.name-date h6 {
  margin-bottom: 0;
}
.name-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  margin: 17px 0 0;
}
p.add-card {
  color: #EE6F22;
  text-decoration: underline;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
  margin: 10px 0 0;
}
.accordion-button::after {
  background-image: url("../images/accordion-down-arrow.png");
  position: absolute;
  top: 19px;
  right: 18px;
  position: absolute;
  background-size: unset;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("../images/accordion-down-arrow.png");
  transform: rotate(-180deg);
}
.checkout-product-detail {
  border-radius: 20px;
  border: 0.5px solid #EE6F22;
  background: #FBE0CF;
  box-shadow: 20px 32px 27px 0px rgba(0, 0, 0, 0.02);
  padding: 4px 7px 4px 15px;
  margin-bottom: 12px;
}
.product-detail-multiple-boxes {
  max-height: 360px;
  overflow: auto;
  padding-right: 6px;
  overflow-x: hidden;
}
.checkout-product-detail-box {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ee6f224f;
  padding: 15px 0;
}
.checkout-product-detail-box {
  display: flex;
  align-items: center;
}
.product-amount {
  border-radius: 20px;
  border: 0.5px solid #EE6F22;
  background: #FBE0CF;
  box-shadow: 20px 32px 27px 0px rgba(0, 0, 0, 0.02);
  padding: 15px;
}
.checkout-product-detail img {
  width: 70px;
  height: 70px;
  border-radius: 6px;
  object-fit: cover;
}
.product-detail-left h5 {
  font-size: 18px;
  color: #ee6f22;
  font-weight: 700;
}
.product-detail-left h6 {
  font-size: 16px;
  color: #000;
  font-weight: 900;
  margin: 9px 0;
}
.product-detail-left p span {
  color: #ee6f22;
}
.product-detail-left p {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}
.product-detail-left {
  margin-left: 20px;
}
.product-amount h6 {
  font-size: 18px;
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 15px;
}
.product-amount p {
  display: flex;
  justify-content: space-between;
  color: #000;
  padding-bottom: 8px;
}
p.grand-total {
  font-size: 18px;
  font-weight: 700;
  margin: 18px 0;
}
.product-amount button {
  background: #EE6F22;
  width: 100%;
  color: #fff !important;
  padding: 10px 0;
  font-weight: 700;
  border-radius: 110px;
  margin-bottom: 15px;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("../images/input-check.png");
} 
.add-address-box .modal-content {
  border-radius: 20px;
  border: 0.5px solid #EE6F22;
  background: #FBE0CF;
  box-shadow: 20px 32px 27px 0px rgba(0, 0, 0, 0.02);
}
.add-address-box .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}
.add-address-box .modal-title {
  font-size: 20px;
  color: #000;
  font-weight: 800;
}
.btn-close:focus {
  box-shadow: none;
}
.add-address-box .modal-body {
  padding-top: 10px;
}
.edit-delete {
  
  display: flex;
  cursor: pointer;
}
p.edit {
  color: #25875C !important;
  text-decoration: underline;
  font-weight: 800 !important;
  margin-right: 12px;
  font-size: 13px !important;
  display: flex;
  align-items: center;
}
p.delete i, p.edit i {
  margin-right: 3px;
}
p.delete {
  color: #FF0000 !important;
  font-size: 13px !important;
  text-decoration: underline;
  font-weight: 800 !important;
  padding-left: 0 !important;
  display: flex;
  align-items: center;
}
.delivery-address-area label {
  padding-right: 5px;
}
p.card-delete {
  position: absolute;
  right: 10px;
  top: -41px;
}
.registration-left.change-password-content {
  padding-right: 0;
}
.manage-addresses-area {
  border-radius: 20px;
  background: #FBE0CF;
  box-shadow: 0px 4px 33px 10px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  height: 100%;
}
.manage-addresses-area h5 {
  background: #ee6f22;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  padding: 20px 25px;
}
.addresses-box-area {
  padding: 35px 70px;
}
.addresses-box {
border-radius: 20px;
border: 0.5px solid rgba(255, 255, 90, 0.20);
background: #FFF;
box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
  padding: 25px 20px;
  position: relative;
  height: 100%;
  margin-bottom: 13px;
}
.addresses-box .form-check-input {
  width: 20px;
  height: 20px;
}
.addresses-box .form-check {
  display: flex;
  align-items: center;
}
.addresses-box p {
  font-size: 14px;
  color: #000;
  font-weight: 500;
  padding-left: 30px;
  line-height: 24px;
}
.addresses-box .form-check label {
  font-size: 16px;
  font-weight: 700;
}
.addresses-edit-delete {
  position: absolute;
  top: 8px;
  right: 14px;
}
.credit-cards-area .addresses-edit-delete {
  top: -42px;
}
.name-date h6 {
  font-weight: 600;
}
.product-quantity-area h6 {
  margin-right: 70px;
}
.product-quantity-area {
  display: flex;
  align-items: center;
}
/* scroll-css-start */
.product-detail-multiple-boxes::-webkit-scrollbar {
  width: 4px;
  }
  /* Track */
  .product-detail-multiple-boxes::-webkit-scrollbar-track {
  background: #ee6f2269;
  border-radius: 10px;
  }
  /* Handle */
  .product-detail-multiple-boxes::-webkit-scrollbar-thumb {
  background: #EE6F22;
  border-radius: 10px;
  }
/* scroll-css-end */
.checkout-product-detail-box:last-child {
  border: none;
}
.registration-left .form-check-input:checked {
  background-color: #fff;
  border-color: #ee6f22;
}
.disabled{
  background-color: 	#E0E0E0 !important;
}
.card-number-area {
  display: flex;
  align-items: center;
}
.card-number-area .form-check-input {
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
}
.card-number-delete-btn {
  position: absolute;
  right: 8px;
  top: -27px;
}
.CardField-input-wrapper {
  height: 40px;
}
.ElementsApp input {
  background-color: transparent;
  border: none;
  display: block;
  font-family: sans-serif;
  font-size: 1em;
  height: 30px !important;
  line-height: 1.2em;
  margin: 0;
  border: 1px solid #000 !important;
  padding: 0;
  width: 200px !important;
}
.card-detail-area label {
  width: 100%;
  font-weight: 700;
  color: #000;
}
.card-detail-area .StripeElement {
  border: 1px solid #d6c0b2;
  padding: 11px;
  border-radius: 110px;
  background: #fbe2d1;
}
.third-area-left ul li {
  color: #000;
  padding: 7px 0;
  font-size: 15px;
}
p.truck-weight-area span {
  margin-right: 5px;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #ee6f22 !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-deny{
  background-color: #000 !important;

}
.manage-addresses-card-area {
  padding: 20px;
}
.manage-addresses-card-area .credit-cards-area {
 margin-bottom: 30px;
}
.clear-filter-area-right {
  display: flex;
  justify-content: end;
}
.filter-resert-btn button {
  display: flex;
  align-items: center;
  background: #ffffff;
  color: #ee6f22 !important;
  height: 48px;
  border-radius: 15px;
  padding: 0 18px;
  border: 1px solid #ee6f22;
  margin-left: 10px;
}
.filter-resert-btn button img {
  width: 11px;
  margin-left: 18px;
}
p.out_of_stok {
  color: red;
  font-style: italic;
  font-weight: 700;
  font-size: 18px;
  margin-top: 28px;
}
.primary-card-detail p img {
  margin-right: 6px;
}
.primary-card-detail h6 {
  margin-bottom: 6px;
}
.add-primary-client-box.modal .modal-content {
  padding: 12px;
}
.success-box .modal-title.h4 {
  color: #000;
  font-weight: 700;
}
.add-primary-client-box .registration-left {
  padding-top: 0;
}
.add-primary-client-box .registration-left form {
  margin-top: 0;
}
.main-spacing{
  min-height: 700px
}
.privacy-policy {
  padding: 100px 0 50px 0;
}
.privacy-policy-content-area {
  background: #fbdfce;
  padding: 30px;
  border-radius: 20px;
  color: #000;
}
.privacy-policy-content-area h2 {
  text-align: center;
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 22px;
}
.privacy-policy-content-area ul h5 {
  font-weight: 800;
  margin-bottom: 8px;
}
.privacy-policy-content-area ul li {
  font-size: 15px;
  list-style: disc;
  padding: 5px 0;
}
.privacy-policy-content-area ul li a {
  color: #ee6f22;
  font-weight: 700;
}
.privacy-policy-content-area ul {
  padding: 22px 0;
}
.privacy-policy-content-area p {
  font-size: 15px;
  margin-bottom: 8px;
}
.footer-center ul li a {
  color: #000 !important;
  text-decoration: none;
}
form.checkout-product-quantity {
  margin-bottom: 0;
}
form.checkout-product-quantity h6 {
  margin-top: 0;
  font-size: 16px;
}
form.checkout-product-quantity .qty input {
  color: #ee6f22;
  width: 35px;
  height: 25px;
  font-size: 16px;
  text-align: center;
  border: none;
  font-weight: 700;
  min-width: 35px;
  background: none;
}
form.checkout-product-quantity .qty button {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ee6f22;
  border-radius: 0;
  padding: 0;
  font-size: 18px;
  color: #ffffff !important;
  font-weight: 700;
}
.checkout-quantity-trash-area {
  display: flex;
  align-items: center;
}
p.checkout-quantity-trash i {
  margin-right: 3px;
}

p.checkout-quantity-trash {
  font-size: 13px;
  color: red;
  text-decoration: underline;
  font-weight: 700;
  margin-left: 15px;
}
.product-image-left button img {
  background: none;
}
.product-image-left button img {
  background: #ee6f2294;
  height: unset !important;
  min-height: unset;
  object-fit: contain !important;
  width: 32px !important;
  padding: 4px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px  ;
}
.disabled {
  background: #69696978;
}
.main-banner-left button {
  background: #fff;
  color: #ee6f22 !important;
  text-decoration: none;
  font-weight: 700;
  padding: 10px 20px;
  border-radius: 120px;
}
.main-banner-left button a {
  color: #ee6f22 !important;
  text-decoration: none;
}
.confirmation-options.modal-body input {
  margin-right: 5px !important;
}
.confirmation-options label.form-check-label {
  text-align: left;
  font-size: 15px;
}
.confirmation-options .form-check {
  display: flex;
  padding-left: 0;
}
.confirmation-options .form-check-input {
  margin: 4px 0 20px;
}
.confirm_title h3 {
  text-align: center;
  font-size: 22px;
  color: #ee6f22;
  font-weight: 800;
}

.confirm_title h6 {
  font-size: 14px;
  text-align: center;
  padding: 4px 0 0 0;
  font-weight: 400;
  color: #000000a1;
  line-height: 16px;
}
p.note {
  color: red !important;
  font-size: 14px !important;
  font-style: italic;
  font-weight: 700 !important;
}
p.note span {
  font-size: 19px;
}
label.unit-area.form-label button {
  background: none;
  border: none;
  color: #000;
  padding: 0 0 0 4px;
  font-weight: 800;
}
label.unit-area.form-label {
  display: flex;
  align-items: center;
}
.product-image-left {
  position: relative;
}
.product-image-left button.tab-left-arrow-btn, .product-image-left button.tab-right-arrow-btn {
  top: 50%;
}
.header a.active.nav-link {
  color: #ee6f22 !important;
  font-weight: 700;
}
.checkout_errors span{
  color: #ee6f22;
}
.order-management-tab-area button.nav-link.active {
  background: none !important;
  color: #ee6f22 !important;
  border-bottom: 2px solid #ee6f22 !important;
  border-radius: unset !important;
}
.order-management-tab-area ul li button {
  font-size: 15px;
  padding: 0;
  font-weight: 600;
  margin: 0 40px 0 0;
}
.order-management-tab-area ul {
  border-top: 1px solid #00000017;
  border-bottom: 1px solid #00000017;
}
.order-management-tab-area .nav-tabs .nav-link {
  margin-bottom: unset;
}
.order-management-content p {
  margin-bottom: 7px;
}
.order-management-content-right .css-13cymwt-control,  .css-b62m3t-container {
  background: none;
  border-color: transparent !important;
}
.order-management-content-right .css-1jqq78o-placeholder {
  grid-area: 1/1/2/3;
  color: #000;
  margin-left: 2px;
  margin-right: 0;
  box-sizing: border-box;
  font-weight: 600;
}
.order-management-content-right .css-1xc3v61-indicatorContainer {
  color: #000;
  padding: 0;
}
.order-management-content-right .css-1nmdiq5-menu {
  top: 100%;
  position: absolute;
  width: 200px;
  z-index: 1;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: -5px;
  box-sizing: border-box;
  right: 0;
}
.order-management-content-right .css-rtg3hk-control, .order-management-content-right .css-yqlmbb-control {
  justify-content: end !important;
}
.order-management-content-right .css-1fdsijx-ValueContainer {
  -webkit-flex: unset !important;
  flex: unset !important;
}
.order-management-content-right {
  justify-content: end;
  display: grid;
}
.order-management-content-right h6 {
  color: #ee6f22;
  font-weight: 800;
  font-size: 18px;
  text-align: right;
}
.manage-truck-detail-left p span i {
  color: #EE6F22;
  font-size: 14px;
  margin-right: 5px;
}
.order-management-content-right button {
  background: #69982C;
  color: #ffff !important;
  border-radius: 90px;
  padding: 4px 15px;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
}
p.time-area {
  display: flex;
  align-items: center;
}
p.time-area button {
  background: none !important;
  border: none;
  padding: 0;
}
p.checkout-note span {
  font-size: 19px;
}
p.checkout-note {
  text-align: center;
  font-size: 14px;
  font-style: italic;
  padding: 0 0 30px;
  line-height: 20px;
  color: #000000a3;
  font-weight: 600;
}
td.status button {
  background: #ee6f22 !important;
  color: #fff !important;
  padding: 2px 16px;
  border-radius: 150px;
  font-size: 14px;
}
tr.table-content-order {
  box-shadow: 0px 4px 33px 10px rgba(0, 0, 0, 0.08);
  border: 0.5px solid #ee6f22;
  border-radius: 15px;
  display: inline-block;
  width: 100%;
  margin-bottom: 13px;
}
tr.table-content-top {
  display: inline-block;
  width: 100%;
}
tr.table-content-order td {
  vertical-align: baseline;
  padding: 20px;
  color: #000;
  width: 32%;
  font-weight: 600;
}
.product-management-detail table tr th {
  font-size: 18px;
  color: #000 !important;
  font-weight: 700;
}
tr.table-content-top th {
  width: 32%;
  border-top: 1px solid rgb(0 0 0 / 8%);
  padding: 15px;
}
.notification-area {
  padding-top: 55px;
}
.notification-content-area h5 {
  font-size: 16px;
  color: #000;
  margin-bottom: 8px;
}
.notification-content-area {
  border-radius: 20px;
  border: 0.5px solid #EE6F22;
  background: #FBE0CF;
  box-shadow: 0px 4px 33px 10px rgba(0, 0, 0, 0.08);
  padding: 20px;
  margin-bottom: 12px;
}
.notification-content-area p {
  color: rgba(0, 0, 0, 0.70);
  font-size: 14px;
  font-weight: 500;
}
.css-13cymwt-control {
  background: #fadecd !important;
}
.css-t3ipsp-control {
      height: 50px;
    border-radius: 40px !important;
    border: 1px solid #00000029  !important;
    background:#fadecd !important;
    box-shadow: none !important;
}
.css-t3ipsp-control:hover {
  border: 1px solid #00000029  !important;
}

.choose-option-area .modal-content {
    padding: 10px;
}
.choose-delivery-option.accordion .accordion-button::after {
  display: none;
}
.distrix-truck-area button {
  background: #EE6F22;
  width: 300px;
  color: #ffff !important;
  font-size: 18px;
  font-weight: 700;
  padding: 10px;
  border-radius: 120px;
  margin-top: 10px;
}
.own-truck-area button {
  background: none;
  width: 300px;
  color: #EE6F22 !important;
  font-size: 18px;
  font-weight: 700;
  padding: 10px;
  border-radius: 120px;
  border: 2px solid #EE6F22;
}
.distrix-truck-area {
  margin-bottom: 15px;
}
.distrix-truck-area label, .own-truck-area label {
  display: flex;
  color: #000;
  font-weight: 700;
}
.own-truck-area input, .distrix-truck-area input {
  border-radius: 46px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  height: 50px;
  background: transparent;
}
.find-loads-area .recent-loads-top-area button {
  background: #ee6f22;
  color: #fff !important;
  font-weight: 600;
  white-space: nowrap;
}
.find-load-right button {
  background: #ee6f22;
  padding: 4px 22px !important;
}
p.date-area {
  display: flex;
  align-items: center;
}
.date-area button {
  background: none !important;
  border: none;
  padding: 0;
}
.select-date-heading.modal-title.h4 {
  font-size: 22px;
}
h6.brand-name span {
  font-size: 14px;
  font-weight: 600;
}
.platform-loads {
  background: rgba(238, 111, 34, 0.20);
  border: rgba(238, 111, 34, 0.20);
}
.add-loads-section {
  padding: 50px 0 0;
}
textarea::-webkit-scrollbar {
  width: 3px;
}

textarea::-webkit-scrollbar-thumb {
  background-color: #ee6f22;
  outline: 0px solid #ee6f22;
}
textarea::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(238 111 34 / 53%);
}
.mb-3.scroll-box textarea {
  border-radius: 10px;
  min-height: 200px;
}
.mb-4.scroll-box textarea {
  border-radius: 10px;
  min-height: 200px;
}
.chat-now-btn-area {
  border-radius: 10px;
  border: 1px solid rgba(238, 111, 34, 0.17);
  background: #FFF;
  box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 10px;
  align-items: center;
}
.brand-name-area h6 {
  margin-bottom: 0;
  text-transform: capitalize;
}
.brand-name-area p {
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.50);
  font-size: 14px;
}
.chat-now-btn-area {
  border-radius: 10px;
  border: 1px solid rgba(238, 111, 34, 0.17);
  background: #FFF;
  box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 12px;
  width: fit-content;
  align-items: center;
  margin-top: 20px;
}
.brand-name-area {
  margin: 0 20px;
}
.chat-now-btn-area button {
  border-radius: 10px;
  border: 1px solid #EE6F22;
  background: #FFF;
  box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.25);
  color: #EE6F22 !important;
  padding: 4px 10px !important;
  white-space: nowrap;
}
.brand-logo-name img {
  width: 63px;
  height: 63px;
  min-width: 63px;
  min-height: 63px;
  border-radius: 50%;
  object-fit: cover;
}
.product-description-area h6 {
  color: #000;
  font-size: 17px;
  font-weight: 700;
}
.product-description-area p {
  color: #0000009e;
  font-weight: 600;
  margin-bottom: 20px;
}
p.note-dashboard {
  font-size: 15px;
  text-align: center;
  color: rgba(0, 0, 0, 0.67);
  font-style: italic;
  font-weight: 600;
  margin-top: 5px;
}
.feature-available .modal-content {
  width: 81%;
}
.feature-available .modal-body h5 {
  margin-bottom: 10px;
}
.order-history-area {
  min-height: 620px;
  padding: 60px 0 0 0;
}
.completed-order-left img {
  width: 110px;
  height: 110px;
  border-radius: 15px;
  object-fit: cover;
}
.completed-orders-detail-area {
  display: flex;
  margin-bottom: 5px;
}
.completed-order-right {
  margin-left: 12px;
}
button.cancel-btn {
  background: #FF0000;
}
.order-history-content ul li button {
  height: 62px;
}
.order-history-content .nav-tabs .nav-link {
  margin-bottom: unset;
  color: #000 !important;
}
.order-history-content .nav-tabs .nav-link {
  border: none;
}
button.accepted-btn {
  background: #2FA2A9;
}
button.ready-to-ship {
  background: #A5B735;
}
button.shipped.btn {
  background: #FFFF55;
  color: #000 !important;
  font-weight: 600;
}
p.delivering-note {
  color: #ee6f22;
  font-weight: 700;
  font-size: 14px;
  font-style: italic;
}
.select-truck-field .css-b62m3t-container {
  border: 1px solid #d9d9d9 !important;
  border-radius: 160px;
  height: 50px;
}  
.select-truck-field .css-1jqq78o-placeholder {
  text-align: left !important;
}
.select-truck-field .css-qbdosj-Input {
  margin: -2px;
  height: 50px;
}
.react-tel-input .form-control{
  width:  100% !important;
}
.product-description-area {
  margin-top: 20px;
}
p.view-more {
  text-align: end;
  color: #ee6f22;
  text-decoration: underline;
  font-weight: 700;
  font-size: 15px;
}
.more-products-area {
  display: flex;
  align-items: center;
}
.more-products-area p {
  color: #ee733e;
  margin: 0 0 0 28px;
  font-weight: 800;
  font-size: 15px;
  align-items: center;
  display: inline-flex;
}
.more-products-area p span {
  font-size: 11px;
  background: #ee6f22;
  color: #fff;
  font-weight: 500;
  width: 22px;
  height: 22px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 4px;
}
.order-detail-area h6 {
  color: #000;
  font-weight: 700;
  font-size: 18px;
}
.order-detail-area p {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}
.order-detail-area p span {
  color: #ee6f22;
  font-weight: 800;
}
.order-detail-area {
  border-top: 1px solid rgba(0, 0, 0, 0.10);
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
  padding: 11px 0;
  margin-bottom: 20px;
}
.order-detail-content-area .completed-order-left img {
  width: 70px;
  height: 70px;
  min-width: 70px;
  min-height: 70px;
  border-radius: 10px;
  object-fit: cover;
}
.order-detail-content-area {
  align-items: center;
}
.order-detail-content-area .truck-detail-bottom p {
  margin-bottom: 0;
  margin-right: 15px;
}
.order-detail-price p {
  color: #ee6f22;
  font-weight: 800;
}
p.total-amount-area span {
  font-weight: 800;
  margin-left: 10px;
}

p.total-amount-area {
  text-align: right;
  color: #000;
  font-size: 17px;
  font-weight: 700;
}
.order-detail-content-area .truck-detail-bottom {
  flex-wrap: wrap;
}
.chat-now-btn-area button i {
  font-size: 18px;
  margin-right: 5px;
}
.contact-content-area {
  display: flex;
  justify-content: space-between;
  padding-bottom: 6px;
}
.contact-popup .modal-content {
  padding: 0 15px;
  width: 80%;
}
.contact-popup .modal-body {
  padding: 15px;
}
.contact-content-area h6 {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  text-align: left;
}
.contact-content-area p {
  color: #969696 !important;
  font-weight: 600;
  font-size: 14px !important;
}
.contact-btn-area {
  display: flex;
  justify-content: end;
}
.contact-btn-area .chat-now-btn-area {
  box-shadow: none;
  background: none;
  border: none;
  padding: 0;
  /* margin-top: 40px; */
}
.platform-loads {
  background: rgba(238, 111, 34, 0.20) ;
}
.view-more{
  cursor: pointer;
}
.back-btn {
  padding: 30px 0 6px 0;
}
.back-btn a {
  font-size: 20px;
  font-weight: 800;
  color: #ee6f22 !important;
  cursor: pointer;
}
.upload-image-top-area img {
  width: 150px;
  min-width: 150px;
  min-height: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 15px;
  display: flex;
  margin: 0 auto;
}
.order-management-content-right .css-b62m3t-container .css-qbdosj-Input {
  padding: 0;
}
.order-management-status .css-1nmdiq5-menu {
  width: 150px;
}
.revenue-summary-left ul {
  display: flex;
  border-radius: 20px;
  border: 0.5px solid #EE6F22;
  background: #FBE0CF;
  box-shadow: 0px 4px 33px 10px rgba(0, 0, 0, 0.08);
  width: 100%;
}
.revenue-summary-left h6, h6.revenue-summary-left {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  color: #000;
  margin-bottom: 12px;
}
.revenue-summary-left ul li {
  margin-right: 0;
  position: relative;
  width: 50%;
  padding: 30px 0 30px 45px;
}
.revenue-summary-left ul li:after {
  content: "";
  position: absolute;
  background: #ee6f22;
  width: 1px;
  height: 100%;
  top: 0;
  right: 0;
}
li.ptimary-clients:after {
  display: none;
}
.revenue-summary-left ul li p {
  color: #000;
  font-weight: 500;
  margin-bottom: 5px;
}
.revenue-summary-left ul h4 {
  color: #ee6f22;
  font-weight: 800;
  font-size: 31px;
}
.revenue-summary-right-content {
  display: flex;
  align-items: center;
}
.revenue-summary-right-content label {
  width: 110px;
}

.revenue-summary-right-content {
  display: flex;
  align-items: center;
}
.revenue-summary-right-content label {
  width: 180px;
  font-weight: 600;
}
.revenue-summary-right-content .css-b62m3t-container {
  width: 100%;
}

.one-time-expense th {
  color: #ee6f22;
}
.one-time-expense table.table {
  margin-bottom: 0;
}
.one-time-expense {
  border-radius: 20px;
  border: 0.5px solid #EE6F22;
  background: #FBE0CF;
  box-shadow: 0px 4px 33px 10px rgba(0, 0, 0, 0.08);
  padding: 6px 18px;
}
.one-time-expense td {
  font-size: 15px;
}
.one-time-expense thead tr {
  border-bottom: 1px solid rgb(62 62 62 / 7%);
}

td.transaction-management-truck-management p span {
  color: #ee6f22;
  font-weight: 800;
  font-size: 14px;
}
td.transaction-management-truck-management p {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.70);
}
td.transaction-management-truck-management h6 {
  font-weight: 700;
  margin-bottom: 2px;
}
.backBtn{
  color: #ee6f22 !important;
}
#delivery_opt {
  background: #2FA2A9;
  font-size: 14px;
  font-weight: 400;
  border-radius: 29px;
  padding: 2px 13px;
  border: none;
}
.guest-user-header .navbar-link {
  color: #000 !important;
  font-size: 15px;
  margin: 0 25px;
  text-transform: unset !important;
}
.products-listing-area .fa {
  position: absolute;
  top: 18px;
  z-index: 999;
  right: 10px;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
}
.products-listing-area input[type="search" i]::-webkit-search-cancel-button {
  display: none;
}
span.note {
  font-size: 10px;
  color: gray;
}
.contact-content-area-box h5 {
  margin-bottom: 0 !important;
  padding: 0;
}
.contact-content-area-box {
  text-align: left;
}
.contact-content-area-box p {
  padding: 0 !important;
}
b.location {
  font-size: 14px;
}
.manage-card-area form p {
  font-size: 17px;
  font-weight: 500;
  justify-content: space-between;
  color: #000;
  display: flex;
  align-items: center;
  padding: 3px 0;
}
.manage-card-area form p span {
  font-weight: 800;
  color: #ee6f22;
  font-size: 16px;
  margin-left: 8px;
}
.manage-card-area form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.card-detail-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.card-detail-top h5 {
  font-weight: 800;
  font-size: 19px;
}
.card-detail-top h6 i {
  margin-right: 3px;
}

.card-detail-top h6 {
    color: #198754;
    font-weight: 700;
    font-size: 14px;
    text-decoration: underline;
}
.orderId{
  color: #ee6f22 !important;
  text-decoration: underline;
  cursor: pointer;
}
.transaction-top-left {
  border-radius: 20px;
  border: 0.5px solid #EE6F22;
  background: #FBE0CF;
  box-shadow: 0px 4px 33px 10px rgba(0, 0, 0, 0.08);
  padding: 15px 40px;
}
.transaction-top-left h4 {
  color: #ee6f22;
  font-size: 30px;
  font-weight: 800;
}
.transaction-top-left p {
  font-weight: 500;
  color: #000;
}
.revenue-summary-right-content .form-control {
  background: transparent;
  border: 1px solid #00000030;
  border-radius: 22px;
}
.revenue-summary-right.revenu-content-right-main .revenue-summary-right-content {
  display: unset;
}
.revenue-summary-right.revenu-content-right-main button.addtruck-btn {
  margin: 34px 0 20px;
}
.credit{
color: #34b020 !important;
}
.debit{
color: #e91313 !important;
}
span.confirm-text {
  font-size: 11px;
  color: #0f0f0ee6;
}
button.export-csv {
  background: #ee6f22;
  color: #fff !important;
  white-space: nowrap;
  padding: 5px 17px;
  border-radius: 28px;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
}

.export-csv-btn {
  display: flex;
  justify-content: end;
}

.upload-trucks-photo i {
  position: absolute;
  right: 18px;
  bottom: 16px;
}

.upload-trucks-photo {
  position: relative;
}
.upload-trucks-photo input#formBasicEmail {
  top: 0;
}
.mt-3.spacing-left .revenue-summary-right-content label {
  width: unset;
  margin-right: 5px;
}
.transaction-top-btns button {
  margin: 0;
  padding: 7px 17px;
}
.transaction-top-btns {
  display: flex;
  align-items: flex-start;
}
.revenue-summary-left.roi-left  {
  margin-right: 0;
  position: relative;
  width: 50%;
  padding: 30px 0 30px 45px;
  border: 1px solid;
  border-color: #ee6f22;
  color: #ee6f22 !important;
   top: 0;
  right: 0;
}
.revenue-summary-left.roi-left h4 {
  padding: 0px 0px 10px 10px;
}
.mid-space-area.row {
  justify-content: space-between;
}
.revenure-list-export-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.third-area-right {
  text-align: center;
}
/* .header nav.bg-body-tertiary.navbar.navbar-expand-lg.navbar-light {
  background: linear-gradient(117deg,#ffffff,#ee6f22)!important;
} */
.navbar-toggler:focus {
  box-shadow: unset !important;
}

.inner-product-table tr.table-content-order td, .inner-product-table tr.table-content-top th {
  width: 12% !important;
  font-size: 14px;
  padding: 12px;
}
.inner-product-table tr.table-content-top th {
  padding: 12px;
  width: 12%;
}
.inner-product-table tr.table-content-order {
  display: flex;
  justify-content: flex-start;
}
.inner-product-table tr.table-content-top {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}
.address-transation {
  WIDTH: 20%;
  width: 28% !important;
}
.inner-product-table .credit {
  width: 16% !important;
  word-wrap: break-word;
}
p.stepper-note-top {
  text-align: center;
  color: gray;
  padding-bottom: 25px;
  font-weight: 600;
}
.header .navbar-toggler {
  padding: 0 3px;
}
.header .navbar-toggler-icon {
  width: 25px;
}
.fade.success-box.add-primary-client-box .css-13cymwt-control {
  background: none !important;
  text-align: left;
}
.change-password-content {
  padding-top: 0;
}
/* a.logo-white.navbar-brand {
  display: none;
} */
.registration-left .form-check {
  display: flex;
  align-items: center;
}
.privacy-policy-content-area h3 {
  font-weight: 800;
  color: #000;
  font-size: 25px;
  padding: 10px 0;
}
.main-home-banner-area img {
  border-radius: 30px;
}

.faq-area h5 {
  color: #000;
  font-weight: 800;
  padding: 20px 0 10px;
}
.accordion-button:not(.collapsed) {
  color: #000;
  background-color: #fff;
  box-shadow: unset;
}
.faq-area .product-management-top h3 {
  font-weight: 900;
  margin-bottom: 10px;
}
a.terms-condition {
  color: #ee6f22;
  font-weight: 700;
}
.roi-top-area {
  background: #fff;
}
.document-preview .first-preview-image {
  background: none;
  border: 1px solid #0000003b;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}
.id-info a {
  color: #ee6f22 !important;
  text-decoration: underline !important;
  font-weight: 600;
  cursor: pointer;
  font-size: 15px;
}
.id-info p {
  margin-bottom: 0;
  color: #605e5e;
  font-size: 16px;
  font-weight: 700;
}
table.inner-product-table.table .wholesaler-side-transaction-top th {
  width: 20% !important;
}
tr.table-content-order.transaction-action.wholesaler-side-transaction-content td {
  width: 20% !important;
}
a.logo-white.navbar-brand {
  display: none;
}
.join-group-links-left p {
  color: #000;
  font-weight: 600;
  /* padding-bottom: 4px; */
}
.join-group-links {
  background: #fbdfce;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 20px;
}
.join-group-links .recent-loads-top-area {
  justify-content: center;
  padding-top: 20px;
}
.join-group-links-left {
  display: flex;
  justify-content: space-between;
}
.join-group-links-left a:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}
.join-group-links-left a {
  background: #ee6f22;
  font-weight: 600;
  border-radius: 20px;
  text-decoration: none;
  padding: 6px 18px;
  color: #fff;
  font-size: 14px;
  background-image: linear-gradient(to right, #ee6f22 0%, #ee6f22 51%, #000000d6 100%);
  filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.25));
  transition: 0.5s;
  background-size: 200% auto;
}
.join-group-links-left ul li {
  margin-bottom: 8px;
}
.product-description-area textarea {
  border: none;
  padding: 0;
  margin: 10px 0 20px 0;
  width: 100%;
  resize: none;
}

.pass-view i {
  position: absolute;
  right: 17px;
  top: 16px;
  color: #000;
  cursor: pointer;
}
.pass-view {
  position: relative;
}
.note_text{
  font-size: 12px !important;
}

/* media-query */
@media (max-width: 1600px) { 
    .registration-area {
    background: url("../images/hero-banner-small.png");
    background-repeat: no-repeat;
    height: 100vh;
    background-position: right;
    display: flex;
    justify-content: center;
    align-items: center;
}
.registration-left {
  padding: 80px 40px 0 0;
}
.dot-vector {

  right: 50%;
}
.registration-left form {
  margin-top: 20px;
}
.registration-left button {
  margin: 0 auto 0;
}
}
@media (max-width: 1400px) {
.faq-area .accordion-button {
    font-size: 15px;
}
.faq-area .accordion-button::after {
  top: 20px;
  right: 5px;
}
.faq-area .accordion-body {
  font-size: 15px;
}
  .dot-vector {
    right: 42%;
}
  .registration-area {
    background: url("../images/hero-banner-lg.png");
    background-repeat: no-repeat;
    height: 100vh;
    background-position: right;
    display: flex;
    justify-content: center;
    align-items: center;
}
  .product-quantity-area h6 {
    margin-right: 20px;
}
  .dashboard-area-left h1 {
    font-size: 35px;
}
button.find-insurance-btn {
  width: 170px;
  min-width: 170px;
  padding: 5px 10px;
}
button.active-request {
  width: 170px;
  min-width: 170px;
  padding: 5px 10px;
}
  .filter-area-right .css-oal77d-control {
    width: 150px;
}
  .credit-cards-area {
    margin-bottom: 30px;
}
  .card-number-area .form-check-input {
    width: 16px;
    height: 16px;
    margin-right: 5px;
}
.credit-cards-area p {
  font-size: 14px;
}
  .product-detail-area {
    min-height: 190px;
}
  .truck-detail p, .truck-detail-bottom p {
    margin-right: 20px;
  }
}
@media (max-width: 1200px) {
  .registration-area {
    background: url("../images/hero-banner-md.png");
    background-repeat: no-repeat;
    height: 100vh;
    background-position: right;
    display: flex;
    justify-content: center;
    align-items: center;
}
  .product-amount button {
    width: fit-content;
    padding: 10px 30px;
}
  .product-quantity-area {
    flex-wrap: wrap;
}
  .product-detail-right h3 {
    font-size: 40px;
}
  .dashboard-hero-banner-area {
    padding: 15px 0;
}
  .manage-addresses-card-area {
    padding: 20px 10px;
  }
  .distrix-content-bg-area {
    padding: 50px 20px 0;
}
}
@media (max-width: 991px) {
  p.stepper-note-top {
    font-size: 15px;
    line-height: 23px;
}
  .StepperContainer-0-2-1 {
    padding: 24px 0 !important;
}
.ConnectorContainer-0-2-14 {
  top: calc((2em - -5px) / 2) !important;
  left: calc((-50% + 33px) - 8px) !important;
  right: calc((50% + 33px) - 8px) !important;
}
  .StepButton-0-2-4 {
    width: 40px !important;
    height: 40px !important;
    background: #ee6f227d!important;
    position: relative;
}
.StepButton-0-2-4:after {
  content: "";
  position: absolute;
  width: 52px;
  height: 52px;
  background: none;
  border-radius: 50%;
  border: 1px solid #ee6f227d;
}
.StepButton-d0-0-2-6.active:after {
  content: "";
  position: absolute;
  width: 52px;
  height: 52px;
  background: no-repeat;
  border-radius: 50%;
  border: 1px solid #ee6f22;
}
  .Label-0-2-9 {
    font-size: 15px;
}
  .create-profile-area {
    padding: 95px 0 70px 0;
}
  a.hide_on_mobile.navbar-brand {
    display: none;
}
a.logo-white.navbar-brand {
  display: block;
}
  .profile-header-top .dropdown-menu.show {
    left: -40px;
    position: absolute;
}
  div#basic-navbar-nav {
    padding: 15px;
    border-radius: 10px;
    background: linear-gradient(118deg,#fef7f3,#f7cbb1);
    box-shadow: 0 13px 35px -12px rgb(35 35 35 / 10%);
}

  /* a.navbar-brand {
    display: none;
} */
/* a.logo-white.navbar-brand {
  display: block;
} */
  .dot-vector {
    display: none;
  }
  .registration-left {
    background: #ffffff;
    padding: 50px;
    border-radius: 40px;
}
  .registration-area {
    background: url("../images/hero-banner.png");
    background-repeat: no-repeat;
    height: 100vh;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
}
  .product-detail-right h3 {
    font-size: 31px;
}
.product-availability-order p {
  font-size: 15px;
  margin-right: 13px;
}
.product-detail-right p {
  font-size: 15px;
  color: #000;
  margin-bottom: 10px;
}
.product-detail-right h6 {
  font-size: 18px;
}
  .product-listing-banner-area-left p {
    margin-bottom: 15px;
}
  .product-listing-banner-area-left h2 {
    font-size: 35px;
    line-height: 45px;
}
  .search-area input {
    width: 100%;
}
  .clear-filter-area-right {
    justify-content: start;
    margin-top: 10px;
}
  .w-dashboard-area .dashboard-area-left h1 {
    font-size: 28px;
    line-height: 35px;
}
  .notification-top {
    width: fit-content;
}
.notification-top span {
  right: -12px;
}
.profile-header-top {
  margin-left: 0;
  width: fit-content;
}
.auth-header a.nav-link {
  margin: 0 0;
}
  .product-management-detail {
    overflow: auto;
}
  .product-management-area {
    flex-wrap: wrap;
}
  .recent-loads-top-area {
    flex-wrap: wrap;
}
.mid-space-area {
  margin-bottom: 28px;
}
.manage-truck-top-area button {
  margin-top: 13px;
}
  .dashboard-area-left {
    padding-left: 20px;
}
.transaction-top-left {
  margin-top: 20px;
}
.dashboard-area-left h1 {
  font-size: 30px;
}
  .main-home-third-area .row {
    flex-direction: column-reverse;
}
.third-area-right, .sales-mage-order-left {
  padding-bottom: 30px;
}
.sales-mage-order-left img {
  width: 380px;
}
.builtin-marketing-area .third-area-right img {
  width: 410px;
}
.builtin-marketing-area .third-area-right {
  padding-top: 40px;
}
.main-home-third-area {
  padding: 0 0 50px;
}
.third-area-right, .sales-mage-order-left {
  text-align: center;
}
.features-and-benefits .row {
  justify-content: center;
}
.footer-left p {
  width: unset;
  font-size: 14px;
  line-height: 24px;
}
 }
 @media (max-width: 767px) { 

  .registration-left h4 {
    font-size: 30px;
 
}
  .product-detail-right {
    margin-top: 15px;
} 
  .w-dashboard-area .dashboard-area-right {
    padding: 0;
}
  .w-dashboard-area .dashboard-area-left h1 {
    margin-top: 7px;
}
  button.change-password-btn, button.edit-profile-btn {
    padding: 4px 10px;
}
.my-profile-top h3 {
  font-size: 22px;

}
.trucker-image-area img {
  width: 140px;
  height: 140px;
  min-width: 140px;
  min-height: 140px;
}
  .dashboard-top-tab-area .swiper-slide button {
    height: 47px;
}
button.tab-left-arrow-btn, button.tab-right-arrow-btn {
  bottom: 9px;
}
  .export-csv-btn {
    justify-content: start;
}
.spacing-left .revenue-summary-right-content label {
  margin-left: 0 !important;
  width: 50px !important;
}
.spacing-left .revenue-summary-right-content {
  margin-bottom: 12px;
}
  .one-time-expense {
    overflow: auto;
}
  .mid-space-area .transaction-top-left {
    margin-top: 18px;
}
  .manage-truck-detail-right {
    margin-top: 12px;
} 
  .order-management-tab-area ul li button {
    margin: 0 20px 0 0;
}
  p.note-dashboard {
    font-size: 14px;
    line-height: 24px;
}
  .dashboard-area-right button {
    margin: 13px auto;
}
.dashboard-area-left h1 span {
    margin: 0 6px;
}
.dashboard-area-left h1 br {
  display: none;
}
.dashboard-area-left h1 {
  text-align: center;
  margin-bottom: 10px;
}
.dashboard-area-left {
  padding-left: 0;
}
  .about-distrix-heading h2 {
    font-size: 35px;
    padding: 2px 15px;
}
.footer-center {
  padding: 30px 0;
}
.footer-right {
  padding: 0;
}
  }
 @media (max-width: 575px) {
  tr.wholesaler-side-transaction-content {
    display: block !important;
}
  .addresses-box-area {
    padding: 35px 10px;
}
  p.stepper-note-top {
    padding-top: 20px;
}
  .StepperContainer-0-2-1 {
    padding: 24px 0 0 !important;
}
  .delivery-address-area {
    flex-wrap: wrap;
    border-radius: 35px;
}
.edit-delete {
  margin: 0 0 0 auto;
}
  .product-image-left img {
    height: 420px;
    min-height: 420px;
}
  .filter-search-area-left {
    flex-wrap: wrap;
}
.search-area {
  margin-top: 8px;
}
  .completed-orders-detail-area {
    flex-wrap: wrap;
}
.completed-order-right {
  margin: 10px 0 0 0;
}
  button.edit-profile-update-btn {
    padding: 8px 50px;
    margin: 30px auto 10px;
    font-size: 14px;
}
  .profile-edit-area i {
    right: 35px;
    width: 35px;
    height: 35px;
}
  .my-profile-area .manage-truck-area {
    padding: 20px 20px;
}
  .trucker-info-detail {
    margin-bottom: 25px;
}
  .order-management-tab-area ul li button {
    margin: 0 15px 0 0;
}
  .dashboard-area {
    padding-top: 90px;
}
  a.navbar-brand img {
    width: 140px;
}
  .footer-center ul li {
    list-style: none;
} 
.footer-center h6 {
  margin-bottom: 6px;
}
  .distrix-content-bg-area {
    padding: 35px 0 0;
}
.about-distrix-heading h2, .feature-benefits-area h2 {
  font-size: 28px;
  padding: 2px 9px;
}
.distrix-content-bg-area p, .third-area-left p {
  line-height: 23px;
  font-size: 14px;
}
.third-area-left h2 {
  font-size: 38px;
}
.third-area-right img {
  width: 450px;
}
.sales-mage-order-left img {
  width: 320px;
}
.builtin-marketing-area .third-area-right img {
    width: 350px;
}
.features-and-benefits .row {
  margin-top: 35px;
}
  }
 @media (max-width: 480px) {
  .join-group-links-left p {
  
    font-size: 14px;
}
  .recent-loads-top-area {
     margin: 0 0 20px 0;
}
  .privacy-policy-content-area h2 {
    font-size: 19px;
    margin-bottom: 12px;
}
.privacy-policy-content-area ul {
  padding: 10px 0;
}
.privacy-policy-content-area {
  padding: 20px;

}
.privacy-policy-content-area ul h5 {
  font-size: 15px;
}
.privacy-policy-content-area ul li {
  font-size: 14px;
}
.privacy-policy-content-area p {
  font-size: 14px;
}
.privacy-policy-content-area h3 {
  font-size: 17px;
}

  .my-profile-area {
    padding-top: 40px;
}
  .product-images img {
    width: 65px;
    height: 65px;
    border-radius: 5px;
    object-fit: cover;
}
.coming-soon-page-content h2 {
  font-size: 30px;
}
.add-address-area button {
  font-size: 15px;
  padding: 7px;
}

.truck-images img {
    width: 65px;
    height: 65px;
    min-width: 65px;
    min-height: 65px;
    object-fit: cover;
    margin-right: 7px;
    border-radius: 7px;
    margin-bottom: 7px;
}
  .transaction-top-left {
    padding: 15px 20px;
}
  p.stepper-note-top {
    font-size: 14px;
    line-height: 20px;
}
  .Label-0-2-9 {
    font-size: 14px;
}
.ConnectorContainer-0-2-14 {
  top: calc((2em - 0px) / 2) !important;
  left: calc((-50% + 30px) - 8px) !important;
  right: calc((50% + 30px) - 8px) !important;
}
  .StepButtonContent-0-2-5 {
    font-size: 16px !important;
}
.StepButton-0-2-4 {
  width: 35px !important;
  height: 35px !important;
  background: #ee6f227d!important;
  position: relative;
}
.StepButton-0-2-4:after {
  content: "";
  position: absolute;
  width: 45px;
  height: 45px;
  background: none;
  border-radius: 50%;
  border: 1px solid #ee6f227d;
}
.StepButton-d0-0-2-6.active:after {
  content: "";
  position: absolute;
  width: 45px;
  height: 45px;
  background: no-repeat;
  border-radius: 50%;
  border: 1px solid #ee6f22;
}
  button.next-btn {
    padding: 6px 100px;
    font-size: 15px;
}
button.submit-btn {
  padding: 7px 100px;
  margin: 40px auto 0;
  font-size: 15px;
}
p.note {
  font-size: 13px !important;
  line-height: 22px;
}
  .create-profile-content-area {
    padding: 10px 10px 20px;
}
  .registration-left label.form-check-label {
    font-size: 13px;
}
  .dashboard-top-tab-area .swiper-slide {
    margin-right: 20px !important;
}
  .product-description-area p {
    color: #0000009e;
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 14px;
}
  .css-oeu81f-control {
    height: 45px;
}
.success-box .modal-footer button {
  padding: 9px 33px;
}
.stepper-form-area form label {
  font-size: 14px;
}
  .distrix-truck-area button, .own-truck-area button {
    width: 100%;
    font-size: 16px;
    padding: 8px;
}
  .dashboard-area-left h1 span {
    margin: 0 3px;
}
  .swiper-button-prev, .swiper-button-next {
    width: 25px;
    height: 25px;
}
.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 14px;
}
  button.sign-in-btn, .orange-btns button.sign-up-btn {
    padding: 5px 30px;
}

  p.password-reset {
    font-size: 14px;
    line-height: 22px;
}
  .form-check-input {
    width: 15px;
    height: 15px;
    min-width: 15px;
    min-height: 15px;
}
  .registration-left {
    padding: 30px;
}
.registration-left .form-label, a.forgot-password-area, p.bottom-text {
  font-size: 15px;
}

.registration-left h4 {
  font-size: 26px;
  padding-left: 15px;
}
  .delivery-address-area label {
    font-size: 14px;
    line-height: 24px;
}
h2.checkout-heading {
  font-size: 26px;
}
.registration-left .form-control {
  height: 45px;
 
}
.registration-left button {
  font-size: 15px;
  padding: 7px 45px;
}
.product-amount button {
  padding: 6px 28px;
  font-size: 14px;
}
p.grand-total {
  font-size: 16px;
}
.checkout-left-area h2.accordion-header button h6, .product-amount h6 {
  font-size: 17px;

}
.product-detail-left h5 {
  font-size: 16px;
}
form.product-quantity h6 {
  margin-right: 10px;
  }
p.checkout-quantity-trash {

  margin-left: 0;
}
.checkout-quantity-trash-area {
  flex-wrap: wrap;
}
  .brand-name-area {
    margin: 0 10px;
} 
.brand-logo-name img {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
}
.brand-name-area h6 {
  font-size: 14px;
}
  .product-image-left img {
    height: 320px;
    min-height: 320px;
}
.product-detail-right h3 {
  font-size: 24px;
}
.product-detail-right h6 {
  font-size: 15px;
}
.product-detail-right button {
  padding: 5px 28px;
}
  .product-listing-banner-area-left h2 {
    font-size: 28px;
    line-height: 40px;
}
  .product-listing-banner-area {
    padding: 30px;
}
  .sub-category {
    margin: 8px 0 0;
}
.search-area {
  width: 185px;
}
.category {
  margin-right: 8px;
}
  .w-dashboard-area .dashboard-area-left h1 {
    font-size: 22px;
}
  .css-13cymwt-control {
    height: 45px !important;
}
  .change-password-box .modal-content {
    padding: 10px;
}
.stepper-form-area .form-control {
  height: 45px;
}
.success-box .modal-footer button {
  font-size: 14px;
}
  .my-profile-top {
    flex-wrap: wrap;
}
.card-detail-top h5 {
  font-size: 17px;
}
.card-detail-top h6 {
  font-size: 13px;
}
.manage-card-area form p {
  font-size: 15px;
  flex-wrap: wrap;
}
.manage-card-area form p span {
  margin-left: 0;
}
.my-profile-area .manage-truck-area {
  padding: 13px;
}
.trucker-info-detail h6, .trucker-info-detail p {
  font-size: 17px;
}
.trucker-info-detail {
  margin-bottom: 20px;
}
.trucker-image-area img {
  width: 120px;
  height: 120px;
  min-width: 120px;
  min-height: 120px;
}
button.change-password-btn, button.edit-profile-btn {
  font-size: 15px;
}
.my-profile-top-right {
  margin-top: 15px;
}
  .notification-content-area {
    padding: 12px;
}
.notification-content-area h5 {
  font-size: 15px;
}
.product-management-top h3 {
  font-size: 22px;
  margin-bottom: 0;
}
  .success-box .modal-title.h4 {
    font-size: 20px;
}
.success-box .modal-content {
  width: 100%;
  padding: 10px 9px;
}
button.addtruck-btn {
  margin: 20px 0 20px;
}
.add-truck-area p {
  font-size: 14px;
  line-height: 23px;
}
  .revenue-summary-left ul li {
    padding: 10px 0 10px 10px;
}
.transaction-top-left h4 {
  font-size: 20px;
}
.add-truck-area h3 {
  font-size: 22px;
}
.revenure-list-export-top {
  flex-wrap: wrap;
}
.revenue-summary-left ul h4 {

  font-size: 20px;
}
  .manage-truck-area {
    padding: 15px;
  }
  .order-management-tab-area ul li button {
    margin: 0 4px 0 0;
}
.dashboard-tab-area ul li {
  margin-right: 5px;
}
  .truck-detail p, .truck-detail-bottom p {
    margin-right: 5px;
}
.recent-loads-top-area h3 {
  font-size: 20px;
  margin-bottom: 5px;
}
.order-management-content p {
  margin-bottom: 2px;
}
.manage-truck-top-area p {
  line-height: 24px;
}
.manage-truck-detail-left p span i {
  margin-right: 2px;
}
  .recent-loads-top-area {
    flex-wrap: wrap;
}
.feature-available .modal-content {
  width: 100%;
}
.recent-loads-top-area button {
  padding: 3px 13px;
  margin-top: 12px;
}
  .dashboard-area-left h1 {
    font-size: 22px;
}
  .footer-left img {
    width: 140px;
}
.footer {
  padding: 30px 0;
}
.feature-benefits-area-left h6 {
  margin-bottom: 5px;
}
.third-area-left h2 {
  font-size: 26px;
  line-height: 35px;
}.third-area-left h2 {
  font-size: 26px;
  line-height: 35px;
}
.feature-benefits-area-left {
  padding: 15px;
}
.about-distrix-heading h2, .feature-benefits-area h2 {
  font-size: 21px;
  padding: 0px 5px;
}
.distrix-content-bg-area {
  padding: 25px 0 0;
}
.features-and-benefits .row {
  margin-top: 25px;
}
.sales-mage-order-left img {
  width: 250px;
}
.builtin-marketing-area .third-area-right img {
  width: 270px;
}
   }
 
